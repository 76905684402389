import axios from "axios";
axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  config.headers.Authorization = token ? `Bearer ${token}` : ``;
  return config;
});
export default axios.create({
  // base url of laravel api

  // baseURL: "https://static.cpc.daffodilvarsity.edu.bd/api",
  baseURL: "https://static.cpc.daffodilvarsity.edu.bd/api",
  // baseURL: "http://localhost:8000/api",
  headers: {
    "content-type": "application/json",
    "X-XRF-TOKEN": getCookie("XSRF-TOKEN"),
  },
});

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}
