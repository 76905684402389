import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../BaseUrl/http";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarMini from "../../Components/Navbar/NavbarMini";
import useAuth from "../../Hooks/useAuth";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import * as pdfjsLib from "pdfjs-dist/webpack";

const formValidate = (
  flags,
  certificate_id,
  approved_by,
  navigate,
  toggleRefresh,
  certificateFile // Pass the certificateFile here
) => {
  document.getElementById("name_y_offset").value === ""
    ? (flags.name_y_offset = true)
    : (flags.name_y_offset = false);
  document.getElementById("name_x_offset").value === ""
    ? (flags.name_x_offset = true)
    : (flags.name_x_offset = false);
  document.getElementById("id_y_offset").value === ""
    ? (flags.id_y_offset = true)
    : (flags.id_y_offset = false);
  document.getElementById("id_x_offset").value === ""
    ? (flags.id_x_offset = true)
    : (flags.id_x_offset = false);
  document.getElementById("c-program").value === ""
    ? (flags.program = true)
    : (flags.program = false);
  document.getElementById("name_size").value === ""
    ? (flags.name_size = true)
    : (flags.name_size = false);
  document.getElementById("id_size").value === ""
    ? (flags.id_size = true)
    : (flags.id_size = false);
  !document.getElementById("c-certificate-input").files.length > 0
    ? (flags.certificate = true)
    : (flags.certificate = false);
  !document.getElementById("c-certificate-preview")
    ? (flags.certificate = true)
    : (flags.certificate = false);

  if (Object.values(flags).every((value) => value === false)) {
    if (
      document
        .getElementById("certificate-submit-button")
        .classList.contains("custom-shake")
    ) {
      document
        .getElementById("certificate-submit-button")
        .classList.remove("custom-shake");
    }
    const name_x_offset = document.getElementById("name_x_offset").value;
    const name_y_offset = document.getElementById("name_y_offset").value;
    const id_x_offset = document.getElementById("id_x_offset").value;
    const id_y_offset = document.getElementById("id_y_offset").value;
    const program_name = document.getElementById("c-program").value;
    const name_size = document.getElementById("name_size").value;
    const id_size = document.getElementById("id_size").value;

    // Use the original PDF file
    const data = {
      certificate_id,
      name_x_offset,
      name_y_offset,
      id_x_offset,
      id_y_offset,
      program_name,
      name_size,
      id_size,
      approved_by,
    };

    const formData = new FormData(); // Create FormData to send files
    formData.append("data", JSON.stringify(data)); // Append the other data
    formData.append("certificate_image", certificateFile); // Append the PDF file

    http
      .post("/createCertificate", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          message.success("Certificate Created Successfully");
        } else {
          message.error("Certificate Creation Failed");
        }
      })
      .catch((error) =>
        message.error(`Certificate Creation Failed. ${error.message}`)
      )
      .finally(() => {
        toggleRefresh();
      });
  } else {
    document
      .getElementById("certificate-submit-button")
      .classList.add("custom-shake");
  }

  return flags;
};

const errorRenderer = (error) => {
  let errorOut = "";
  if (error === "name_y_offset") {
    errorOut = "Please insert Name Y Offset";
  } else if (error === "name_x_offset") {
    errorOut = "Please insert Name X Offset";
  } else if (error === "id_y_offset") {
    errorOut = "Please insert ID Y Offset";
  } else if (error === "id_x_offset") {
    errorOut = "Please insert ID X Offset";
  } else if (error === "program") {
    errorOut =
      "Please insert the name of the program student is being certified for";
  } else if (error === "name_size") {
    errorOut = "Please insert the name size of the Name";
  } else if (error === "id_size") {
    errorOut = "Please insert the id size of the Name";
  } else if (error === "certificate") {
    errorOut = "Please insert certificate image";
  }
  return (
    <>
      <span className="text-xs text-error">({errorOut}) </span>
      <span className="font-bold text-error inline-flex custom-bounce">!</span>
    </>
  );
};

const NewCertificate = () => {
  const navigate = useNavigate();
  const { randomIDGenerator, toggleRefresh, user } = useAuth();
  const [certificateFile, setCertificateFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [formError, setFormError] = useState({
    name_x_offset: false,
    name_y_offset: false,
    id_x_offset: false,
    id_y_offset: false,
    program: false,
    name_size: false,
    id_size: false,
    certificate: false,
  });

  const handleCertificate = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setCertificateFile(file); // Store the original PDF file
      await generatePreview(file);
    }
  };

  const generatePreview = async (file) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const pdfData = new Uint8Array(fileReader.result);
      const loadingTask = pdfjsLib.getDocument(pdfData);
      loadingTask.promise.then(async (pdf) => {
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        await page.render(renderContext).promise;
        const imgData = canvas.toDataURL();
        setPreviewImage(imgData); // Set the preview image
      });
    };
    fileReader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    const dropArea = document.querySelector(".c-drag-area");
    const dropAreaMain = document.getElementById("c-drag-area-main");
    const validImages = ["application/pdf"];

    dropArea?.addEventListener("dragover", (e) => {
      e.preventDefault();
      if (dropAreaMain) {
        dropAreaMain.classList.remove("hover:border-slate-400");
        dropAreaMain.classList.add("border-blue-400");
      }
    });

    dropArea?.addEventListener("dragleave", (e) => {
      e.preventDefault();
      if (dropAreaMain) {
        dropAreaMain.classList.remove("border-blue-400");
        dropAreaMain.classList.add("hover:border-slate-400");
      }
    });

    dropArea?.addEventListener("drop", (e) => {
      e.preventDefault();
      let files = e?.dataTransfer?.files;
      if (files.length > 1) {
        message.warning("Only one certificate is allowed");
      } else {
        const file = files[0];
        if (validImages.includes(file.type)) {
          handleCertificate({ target: { files: [file] } });
        } else {
          message.error("Only PDF files are allowed.");
        }
      }
    });
  }, []);

  useDocumentTitle("Admin - New Certificate");

  return (
    <div className="bg-slate-100 dark:bg-slate-900 dark:text-slate-200 min-h-screen">
      {/* Navbar */}
      <div>
        <div className="hidden xl:block">
          <Navbar />
          <div className="h-12 2xl:h-16"></div>
        </div>
        <div className="xl:hidden">
          <NavbarMini />
        </div>
      </div>
      <div className="mt-5 md:mt-16 min-h-[50vh] md:min-h-[70vh]">
        <div className="bg-white dark:bg-slate-800 w-11/12 xl:w-2/3 rounded-md shadow-sm p-3 mx-auto grid grid-cols-1 xl:grid-cols-3 gap-y-5 xl:gap-5">
          <div className="col-span-2 p-3">
            {/* Certified For */}
            <div>
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Program Name{" "}
                  {formError.program === true ? errorRenderer("program") : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="Program Name"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="c-program"
              />
            </div>
            {/* Name X-Offset */}
            <div>
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Name X-Offset{" "}
                  {formError.name_x_offset === true
                    ? errorRenderer("name_x_offset")
                    : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="Name X-Offset"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="name_x_offset"
              />
            </div>
            {/* Y-Offset */}
            <div className="mt-3">
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Name Y-Offset{" "}
                  {formError.name_y_offset === true
                    ? errorRenderer("name_y_offset")
                    : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="Name Y Offset"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="name_y_offset"
              />
            </div>
            {/* Name X-Offset */}
            <div>
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  ID X-Offset{" "}
                  {formError.id_x_offset === true
                    ? errorRenderer("id_x_offset")
                    : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="ID X-Offset"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="id_x_offset"
              />
            </div>
            {/* Y-Offset */}
            <div className="mt-3">
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  ID Y-Offset{" "}
                  {formError.id_y_offset === true
                    ? errorRenderer("id_y_offset")
                    : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="ID Y Offset"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="id_y_offset"
              />
            </div>
            {/* Name Size */}
            <div className="mt-3">
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Name Size{" "}
                  {formError.name_size === true
                    ? errorRenderer("name_size")
                    : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="Name Size"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="name_size"
              />
            </div>
            {/* ID Size */}
            <div className="mt-3">
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  ID Size{" "}
                  {formError.id_size === true ? errorRenderer("id_size") : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="ID Size"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="id_size"
              />
            </div>
          </div>
          <div>
            <label className="label">
              <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                Certificate Image{" "}
                {formError.certificate === true
                  ? errorRenderer("certificate")
                  : ""}
              </span>
            </label>
            <div>
              <div className="hidden">
                <input
                  type="file"
                  id="c-certificate-input"
                  accept="application/pdf"
                  onChange={handleCertificate}
                />
              </div>
              <button
                onClick={() => {
                  document.getElementById("c-certificate-input").click();
                }}
                className="w-full"
              >
                <div className="w-full">
                  {!certificateFile ? (
                    <div
                      id="c-drag-area-main"
                      className="text-slate-700 dark:text-slate-300 font-medium text-center border-2 hover:border-slate-400 border-dashed p-5 rounded-md transition ease-in-out duration-500 c-drag-area"
                    >
                      Upload Certificate Template
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {previewImage ? (
                  <div className="rounded-md mt-5 c-drag-area">
                    <img
                      id="c-certificate-preview"
                      src={previewImage}
                      alt="Preview"
                      className="w-full rounded-md"
                    />
                  </div>
                ) : (
                  ""
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="w-11/12 xl:w-2/3 p-3 mx-auto flex items-center justify-center">
          <button
            id="certificate-submit-button"
            className="btn btn-sm text-white hover:text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 border-none"
            onClick={() => {
              const validatedError = formValidate(
                formError,
                randomIDGenerator(8),
                user?.name,
                navigate,
                toggleRefresh,
                certificateFile // Pass the certificateFile here
              );
              setFormError({ ...formError, ...validatedError });
            }}
          >
            Submit
          </button>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default NewCertificate;
