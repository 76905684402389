import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarMini from "../../Components/Navbar/NavbarMini";
import useAuth from "../../Hooks/useAuth";
import useDocumentTitle from "../../Hooks/useDocumentTitle";

const Contest = () => {
  const contestId = useParams().contestId;
  const { contests } = useAuth();
  let contest = contests.filter(
    (x) => x.contest_id.toLowerCase() === contestId.toLowerCase()
  );
  contest = contest[0]?.contest_id ? contest[0] : [];
  useDocumentTitle(`Contest - ${contest?.title}`);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  if (!/^\b[a-zA-Z0-9_]+\b$/.test(contestId) || contest?.length === 0) {
    return (
      <div className="bg-slate-100 dark:bg-slate-900 dark:text-slate-200 h-screen flex items-center justify-center">
        <div className="text-center">
          <div className="text-3xl font-semibold text-slate-600 dark:text-slate-400">
            Contest Not Found
          </div>
          <div className="mt-2 text-slate-600 dark:text-slate-400">
            Check if the Link/ID is correct.{" "}
            <Link
              to="/forum/contests"
              className="hover:text-orange-500 font-semibold"
            >
              Go Back
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-slate-100 dark:bg-slate-900 dark:text-slate-200">
      {/* Navbar */}
      <div>
        <div className="hidden xl:block">
          <Navbar></Navbar>
          <div className="h-12 2xl:h-16"></div>
        </div>
        <div className="xl:hidden">
          <NavbarMini></NavbarMini>
        </div>
      </div>

      <div className="mt-5 md:mt-16 min-h-[50vh] md:min-h-[70vh] w-11/12 xl:w-2/3 rounded-md mx-auto">
        <div className="rounded-lg shadow-sm mb-5">
          <img
            src={`https://static.cpc.daffodilvarsity.edu.bd/${contest?.cover_image}`}
            alt="Event Cover"
            className="w-full rounded-lg shadow-sm"
          />
        </div>
        <div className="bg-white dark:bg-slate-800 rounded-lg shadow-sm p-5">
          <div className="text-xl font-semibold text-slate-600 dark:text-slate-200">
            {contest?.title}
          </div>
          <div className="divider before:bg-slate-200 dark:before:bg-slate-700 after:bg-slate-200 dark:after:bg-slate-700 xl:hidden"></div>
          <div className="text-slate-400 text-sm whitespace-pre-wrap break-words">
            {contest?.description}
          </div>
        </div>
        <div className="flex justify-center">
          <a href={contest?.registration_link} target="_blank">
            <button
              id="event-register-button"
              className="mt-5 btn btn-sm text-white hover:text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 border-none"
            >
              Start Now
            </button>
          </a>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-10">
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Contest;
