import { Image } from "antd";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarMini from "../../Components/Navbar/NavbarMini";
import useAuth from "../../Hooks/useAuth";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/web/pdf_viewer.css"; // Optional, if you want the default PDF viewer styles
import { PDFDocument } from "pdf-lib"; // Import pdf-lib for PDF generation

<Helmet>
  <title>
    Verify Certificate - Daffodil International University Computer and
    Programming Club (DIU CPC)
  </title>
  <meta
    name="description"
    content="Easily verify the authenticity of certificates issued by the Daffodil International University Computer & Programming Club."
  />
  <meta
    property="og:title"
    content="Certificate Verification - DIU Computer & Programming Club"
  />
  <meta
    property="og:description"
    content="Authenticate certificates issued by the Daffodil International University Computer & Programming Club with our verification tool."
  />
  <meta property="og:image" content="%PUBLIC_URL%/images/DIUCPC-logo.png" />
  <meta
    property="og:url"
    content="https://cpc.daffodilvarsity.edu.bd/verify-certificate"
  />
  <link
    rel="preload"
    href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
    as="style"
  />
  <link
    rel="preload"
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"
    as="style"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
    rel="stylesheet"
  />
  <link
    href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"
    rel="stylesheet"
  />
</Helmet>;

const CertificateVerify = () => {
  const { certificates, user, participants } = useAuth();
  const [certificate, setCertificate] = useState([]);
  const [pdfPreview, setPdfPreview] = useState(null); // URL for preview
  const [imagePreview, setImagePreview] = useState(null); // Image from PDF
  const id = useParams().id?.toLowerCase();
  let canvas = null; // Add a reference for the canvas

  const certificateLoad = (id) => {
    if (id !== "") {
      const filteredCertificate = certificates.filter(
        (x) => x.certificate_id.toLowerCase() === id
      );
      if (filteredCertificate?.length === 0) {
        setCertificate([{ certificate_id: "empty" }]);
      } else {
        setCertificate(filteredCertificate);
      }
    } else {
      setCertificate([]);
    }
  };

  useEffect(() => {
    if (id) {
      certificateLoad(id.toLowerCase());
    }
  }, [id, certificates]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleSearchCertificate = async () => {
    const stu_id = document.querySelector("#stu_id").value;
    const program_name = document.querySelector("#program_name").value;

    const filteredParticipant = participants?.filter(
      (item) => item?.stu_id === stu_id && item?.program_name === program_name
    )[0];

    const filteredCertificate = certificates?.filter(
      (item) => item?.program_name === filteredParticipant?.program_name
    )[0];

    if (filteredParticipant && filteredCertificate) {
      // const CertificateTemplate = `https://static.cpc.daffodilvarsity.edu.bd/api/certificates/${
      //   filteredCertificate.certificate_image?.split("/")[2]
      // }`;
      const CertificateTemplate = `https://static.cpc.daffodilvarsity.edu.bd/api/certificates/${
        filteredCertificate.certificate_image?.split("/")[2]
      }`;
      setPdfPreview(CertificateTemplate); // Store the PDF URL
      renderPdfAsImage(
        CertificateTemplate,
        filteredCertificate,
        filteredParticipant
      ); // Render the PDF as an image
    }
  };

  const renderPdfAsImage = async (
    pdfUrl,
    filteredCertificate,
    filteredParticipant
  ) => {
    await document.fonts.load("40px Alex Brush");
    await document.fonts.load("25px Poppins");
    const loadingTask = pdfjsLib.getDocument(pdfUrl);
    loadingTask.promise.then((pdf) => {
      pdf.getPage(1).then((page) => {
        const viewport = page.getViewport({ scale: 1 });
        canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        page.render(renderContext).promise.then(() => {
          // Draw the student name and ID text on the canvas
          if (filteredParticipant && filteredCertificate) {
            const { stu_name, stu_id } = filteredParticipant;
            const {
              name_x_offset,
              name_y_offset,
              id_x_offset,
              id_y_offset,
              name_size,
              id_size,
            } = filteredCertificate || {};

            // Render the student's name
            context.font = `${name_size}px Alex Brush`;
            context.fillStyle = "black"; // Text color, can be customized
            context.fillText(stu_name, name_x_offset, name_y_offset);

            context.font = `${id_size}px Poppins`;
            context.fillStyle = "black";
            context.fillText(`ID: ${stu_id}`, id_x_offset, id_y_offset);

            // Update the image preview with the canvas
            setImagePreview(canvas.toDataURL());
          }
        });
      });
    });
  };

  const downloadModifiedPdf = async () => {
    if (canvas) {
      // Create a new PDF document
      const pdfDoc = await PDFDocument.create();
      const page = pdfDoc.addPage([canvas.width, canvas.height]);

      // Convert the canvas to an image and add it to the PDF
      const pngImageBytes = await canvas.toDataURL("image/png").split(",")[1];
      const pngImage = await pdfDoc.embedPng(pngImageBytes);
      page.drawImage(pngImage, {
        x: 0,
        y: 0,
        width: canvas.width,
        height: canvas.height,
      });

      // Download the new PDF with drawn text
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "certificate_modified.pdf";
      link.click();
    }
  };

  const downloadImage = () => {
    if (imagePreview) {
      // Create a link element
      const link = document.createElement("a");
      link.href = imagePreview; // Use the image preview URL
      link.download = "certificate.png"; // Set download file name
      link.click(); // Trigger the download
    }
  };

  return (
    <div className="bg-slate-100 dark:bg-slate-900 dark:text-slate-200">
      <Helmet>
        <title>
          Verify Certificate - Daffodil International University Computer and
          Programming Club (DIU CPC)
        </title>
        <meta
          name="description"
          content="Easily verify the authenticity of certificates issued by the Daffodil International University Computer & Programming Club."
        />
        <meta
          property="og:title"
          content="Certificate Verification - DIU Computer & Programming Club"
        />
        <meta
          property="og:description"
          content="Authenticate certificates issued by the Daffodil International University Computer & Programming Club with our verification tool."
        />
        <meta
          property="og:image"
          content="%PUBLIC_URL%/images/DIUCPC-logo.png"
        />
        <meta
          property="og:url"
          content="https://cpc.daffodilvarsity.edu.bd/verify-certificate"
        />
      </Helmet>

      {/* Navbar */}
      <div>
        <div className="hidden xl:block">
          <Navbar />
          <div className="h-12 2xl:h-16"></div>
        </div>
        <div className="xl:hidden">
          <NavbarMini />
        </div>
      </div>

      {/* Body */}
      <div className="min-h-[50vh] md:min-h-[70vh]">
        <div className="mt-10 flex w-3/4 mx-auto">
          {user && user?.role !== "user" ? (
            <>
              <div className="bg-white dark:bg-slate-800 p-3 rounded-md shadow-sm w-full font-semibold">
                All Certificates
              </div>
              <Link
                to="/admin/new-certificate"
                className="group hover:text-orange-500 ml-3"
              >
                <div className="flex bg-white dark:bg-slate-800 rounded-lg px-2 h-full w-max group-hover:px-5 overflow-hidden items-center justify-center shadow-sm duration-300">
                  Create New Certificate
                </div>
              </Link>
              <Link
                to="/admin/new-participant"
                className="group hover:text-orange-500 ml-3"
              >
                <div className="flex bg-white dark:bg-slate-800 rounded-lg px-2 h-full w-max group-hover:px-5 overflow-hidden items-center justify-center shadow-sm duration-300">
                  Add Participant
                </div>
              </Link>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <div className="flex items-center gap-x-5 w-3/4 mx-auto my-5">
            <input
              className="p-3 rounded-lg bg-white w-full"
              placeholder="Student ID"
              id="stu_id"
            />
            <input
              className="p-3 rounded-lg bg-white w-full"
              placeholder="Program Name"
              id="program_name"
            />
          </div>
          <div className="w-full flex justify-center mt-5">
            <button
              className="font-bold text-white bg-blue-600 hover:bg-blue-700 duration-300 rounded-md px-5 py-1.5"
              onClick={handleSearchCertificate}
            >
              Search Certificate
            </button>
          </div>
        </div>
        {/* Image Preview from PDF */}
        {imagePreview && (
          <div className="w-full flex justify-center mt-5">
            <img src={imagePreview} alt="PDF Preview" width="600" />
          </div>
        )}
        {/* PDF Download Button */}
        {pdfPreview && (
          <div className="w-full flex justify-center mt-5">
            <button
              className="font-bold text-white bg-green-600 hover:bg-green-700 duration-300 rounded-md px-5 py-1.5"
              onClick={downloadImage}
            >
              Download Certificate PDF
            </button>
          </div>
        )}
      </div>

      {/* Footer */}
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default CertificateVerify;
