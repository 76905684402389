import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import http from "../../BaseUrl/http";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import NavbarMini from "../../Components/Navbar/NavbarMini";
import useAuth from "../../Hooks/useAuth";
import useDocumentTitle from "../../Hooks/useDocumentTitle";
import * as pdfjsLib from "pdfjs-dist/webpack";

const formValidate = (
  flags,
  participant_id,
  approved_by,
  navigate,
  toggleRefresh
) => {
  document.getElementById("stu_email").value === ""
    ? (flags.stu_email = true)
    : (flags.stu_email = false);
  document.getElementById("stu_name").value === ""
    ? (flags.stu_name = true)
    : (flags.stu_name = false);
  document.getElementById("stu_id").value === ""
    ? (flags.stu_id = true)
    : (flags.stu_id = false);
  document.getElementById("program_name").value === ""
    ? (flags.program_name = true)
    : (flags.program_name = false);

  if (Object.values(flags).every((value) => value === false)) {
    if (
      document
        .getElementById("certificate-submit-button")
        .classList.contains("custom-shake")
    ) {
      document
        .getElementById("certificate-submit-button")
        .classList.remove("custom-shake");
    }
    const stu_name = document.getElementById("stu_name").value;
    const stu_email = document.getElementById("stu_email").value;
    const stu_id = document.getElementById("stu_id").value;
    const program_name = document.getElementById("program_name").value;

    // Use the original PDF file
    const data = {
      participant_id,
      stu_name,
      stu_email,
      stu_id,
      program_name,
      approved_by,
    };

    http
      .post("/createParticipant", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          message.success("Certificate Created Successfully");
        } else {
          message.error("Certificate Creation Failed");
        }
      })
      .catch((error) =>
        message.error(`Certificate Creation Failed. ${error.message}`)
      )
      .finally(() => {
        window.location.reload();
      });
  } else {
    document
      .getElementById("certificate-submit-button")
      .classList.add("custom-shake");
  }

  return flags;
};

const errorRenderer = (error) => {
  let errorOut = "";
  if (error === "stu_email") {
    errorOut = "Please insert Y Offset";
  } else if (error === "stu_name") {
    errorOut = "Please insert X Offset";
  } else if (error === "stu_id") {
    errorOut =
      "Please insert the name of the program student is being certified for";
  } else if (error === "program_name") {
    errorOut = "Please insert the size of the Name";
  } else if (error === "certificate") {
    errorOut = "Please insert certificate image";
  }
  return (
    <>
      <span className="text-xs text-error">({errorOut}) </span>
      <span className="font-bold text-error inline-flex custom-bounce">!</span>
    </>
  );
};

const NewParticipant = () => {
  const navigate = useNavigate();
  const { randomIDGenerator, toggleRefresh, users, user } = useAuth();
  const [targetUser, setTargetUser] = useState({ name: "", email: "" });
  const [formError, setFormError] = useState({
    stu_name: false,
    stu_email: false,
    stu_id: false,
    program_name: false,
    certificate: false,
  });

  const handleTargetUser = (id) => {
    const target = users.find((x) => x.stu_id === id);
    console.log("Taget User: ", target);
    let x = { name: "", email: "" };
    if (target) {
      x.name = target?.name;
      x.email = target?.email;

      setTargetUser(x);
    }
  };

  useDocumentTitle("Admin - New Certificate");

  return (
    <div className="bg-slate-100 dark:bg-slate-900 dark:text-slate-200 min-h-screen">
      {/* Navbar */}
      <div>
        <div className="hidden xl:block">
          <Navbar />
          <div className="h-12 2xl:h-16"></div>
        </div>
        <div className="xl:hidden">
          <NavbarMini />
        </div>
      </div>
      <div className="mt-5 md:mt-16 min-h-[50vh] md:min-h-[70vh]">
        <div className="bg-white dark:bg-slate-800 w-11/12 xl:w-2/3 rounded-md shadow-sm p-3 mx-auto grid grid-cols-1 xl:grid-cols-3 gap-y-5 xl:gap-5">
          <div className="col-span-2 p-3">
            {/* Certified For */}
            <div>
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Studen ID{" "}
                  {formError.stu_id === true ? errorRenderer("stu_id") : ""}
                </span>
              </label>
              <input
                onBlur={(e) => {
                  handleTargetUser(e?.target.value);
                }}
                onKeyUp={(key) => {
                  if (key.code === "Enter" || key.code === "NumpadEnter") {
                    handleTargetUser(key?.target.value);
                  }
                }}
                autoComplete="off"
                placeholder="Student ID"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="stu_id"
              />
            </div>
            {/* X-Offset */}
            <div>
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Student Name{" "}
                  {formError.stu_name === true ? errorRenderer("stu_name") : ""}
                </span>
              </label>
              <input
                defaultValue={targetUser?.name}
                autoComplete="off"
                placeholder="Student Name"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="stu_name"
              />
            </div>
            {/* Y-Offset */}
            <div className="mt-3">
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Student Email{" "}
                  {formError.stu_email === true
                    ? errorRenderer("stu_email")
                    : ""}
                </span>
              </label>
              <input
                defaultValue={targetUser?.email}
                autoComplete="off"
                placeholder="Student Email"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="stu_email"
              />
            </div>
            {/* Size */}
            <div className="mt-3">
              <label className="label">
                <span className="label-text text-slate-700 dark:text-slate-300 font-medium">
                  Program Name{" "}
                  {formError.program_name === true
                    ? errorRenderer("program_name")
                    : ""}
                </span>
              </label>
              <input
                autoComplete="off"
                placeholder="Program Name"
                className="rounded-md w-full px-3 py-1 border ring-2 ring-transparent outline-none border-slate-300 hover:border-blue-400 focus:border-blue-400 focus:ring-blue-100 transition duration-200 dark:text-slate-800"
                id="program_name"
              />
            </div>
          </div>
        </div>
        <div className="w-11/12 xl:w-2/3 p-3 mx-auto flex items-center justify-center">
          <button
            id="certificate-submit-button"
            className="btn btn-sm text-white hover:text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 border-none"
            onClick={() => {
              const validatedError = formValidate(
                formError,
                randomIDGenerator(8),
                user?.name,
                navigate,
                toggleRefresh
              );
              setFormError({ ...formError, ...validatedError });
            }}
          >
            Submit
          </button>
        </div>
      </div>

      {/* Footer */}
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
};

export default NewParticipant;
